@import '../../sass/layout';
@import '../../sass/colors';

$nav-header-height: $layout-header-height;
$menu-collapsed-width: 80px;

:local(.sider) {
  position: relative;
  z-index: 10;
}

:local(.menuStyle) {
  width: 100%;
  max-height: calc(100vh - $layout-header-height);
  overflow-y: auto;
  overflow-x: hidden;
}

.menuItem{
  font-size: 12px!important;
  height: auto!important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;

  * {
    line-height: 20px !important;
  }
}

.menuItem[data-level="0"] {
  font-size: 14px !important;
}

.subMenu {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  :first-child {
    line-height: 30px !important;
    white-space: break-spaces;
    height: auto !important;
  }
}

.sideMenuItem{
  word-wrap: break-word;
  width: 100%;
  white-space: normal;
  line-height: 20px;
}